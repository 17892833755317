import React from 'react';

const Menu = ({ toggleMenu, setPage }) => {
    return (
      <div style={{ width: '200px', backgroundColor: '#f0f0f0', height: '100%', position: 'fixed', top: 0, left: 0 }}>
        <h2 onClick={toggleMenu}>Menu</h2>
        <hr />
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { setPage('switchApp'); toggleMenu(); }}>Disable App</li>
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { setPage('logs'); toggleMenu(); }}>Logs</li>
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { setPage('doublons'); toggleMenu(); }}>Détection doublons</li>
        </ul>
      </div>
    );
  };

export default Menu;