import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';

const Login = ({ connexion }) => {
  const [user, setUser] = useState({ email:'', password:'', error:false, token:'', isConnected:false });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://devadmin.myleader.fr/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email:user.email, password:user.password }),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log('Token 1:', data.token);
        setUser({...user,  isConnected:true, token:data.token, error:false });
        connexion(data.token);
      } else {
        console.error('Erreur de connexion');
        setUser({...user,  error:true});
      }
    } catch (error) {
      console.error('Erreur:', error);
    }
  };


  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            value={user.email}
            onChange={(e) => setUser({...user,  email:e.target.value})}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            value={user.password}
            onChange={(e) => setUser({...user, password:e.target.value})}
            required
            fullWidth
            margin="normal"
          />
          {user.error && <Typography color="error">Something went wrong !</Typography>}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Login;