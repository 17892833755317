import React, { useEffect, useState} from 'react';

const Doublons = ({token}) => {

const [doublons, setDoublons] = useState([]);

useEffect(() => {
    // console.log('Logs Token:', token);
    fetch('https://devadmin.myleader.fr/api/doublons', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    })
    .then(response => response.json())
    .then(data => {
        console.log('Doublons:', data);
        setDoublons(data);
    })
    .catch(error => {
        console.error('Erreur:', error);
    });
  }, [token]);


  return (
    <div>
      <h2>Détection des doublons</h2>
      {doublons.length > 0 ? ("Pas de doublon") : (doublons.length + " doublons détectés")}
    </div>
  );
};

export default Doublons;