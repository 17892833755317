import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';



const Pannel = ({ token }) => {


    const [state, setState ] = useState({ statut:'.....', color:'#f5f5f5' });
    
function getStatus() {

    // console.log('Pannel Token:', token);   

    return fetch('https://devadmin.myleader.fr/api/status', {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    },
});
}

function setEnable() {
    return fetch('https://devadmin.myleader.fr/api/status?mode=enable', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    })
        .then(response => response.json())
        .then(data => {
            // console.log('Status:----', data.statut, data[0].statut);
            let c = data[0].statut.includes('en ligne') ? '#4caf50' : '#df1a0e';
            setState({ statut: data[0].statut, color:c });
        })
        .catch(error => {
            console.error('Erreur:', error);
        });
        }

        function setDisable() {
            return fetch('https://devadmin.myleader.fr/api/status?mode=disable', {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Authorization': 'Bearer ' + token,
                },
            })
            .then(response => response.json())
            .then(data => {
                // console.log('Status:----', data.statut, data[0].statut);

                let c = data[0].statut.includes('en ligne') ? '#4caf50' : '#df1a0e';
                setState({ statut: data[0].statut, color:c });
            })
            .catch(error => {
                console.error('Erreur:', error);
            });
            }

useEffect(() => {
    getStatus()
    .then(response => response.json())
    .then(data => {
        let c = data[0].statut.includes('en ligne') ? '#4caf50' : '#df1a0e';
        setState({ statut: data[0].statut, color:c });
    })
    .catch(error => {
        console.error('Erreur:', error);
    });
}, []);


// const Pannel = () => {

return (
  <div 
  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around'
     ,minHeight:'50vh'
        ,width:'80%', margin:'auto'
        ,maxWidth:'400px'
    ,  Height:'300px', padding:'1em', backgroundColor:'#f5f5f5'
}}
  >
    <h1>Statut de l'application</h1>

    <TextField
            // label="Statut"
            // type="email"
            value={state.statut}
            // onChange={(e) => setUser({...user,  email:e.target.value})}
            // required
            
            fullWidth
            margin="normal"
            sx={{ backgroundColor: state.color }}
          />
    <br />
    <Button 
    variant="contained" color="primary"
    fullWidth
    onClick={setEnable}>Activer l'application</Button>    
    <br />
    <br />
    <Button 
    variant="contained" color="primary"
    fullWidth
    onClick={setDisable}>Désactiver l'application</Button>
  </div>
);

// }
}






export default Pannel;